import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import { isBrowser } from "../utilities/checkEnvironment";
import components from "../components/slices";

const AboutPage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const about = page.data;
  const [customMargin, setCustomMargin] = useState(80);

  const { lectures } = page.data
  useEffect(() => {
    if (isBrowser) {
      const pageTitleElem = document.querySelector(".page-title");
      if (pageTitleElem) {
        setCustomMargin(pageTitleElem.getClientRects()[0].x + 80);
        // window.addEventListener("resize", () =>
        //   setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        // );
        // return () =>
        //   window.removeEventListener("resize", () =>
        //     setCustomMargin(pageTitleElem.getClientRects()[0].x + 80)
        //   );
      }
    }
    return null;
  }, []);

  return (
    <>
      <Seo page={page} />
      <Layout>
        <Container>
          <h1 className="page-title pt-5 mb-0">{about.title}</h1>
          <h3 className="pb-5">{about.subtitle}</h3>
        </Container>
        <div
          style={{ marginLeft: customMargin }}
          className="about-image-wraper"
        >
          <p className="text-light">Photographer: {about.photographer_name}</p>
          <GatsbyImage
            image={about.image_mobile.gatsbyImageData}
            alt={about.image_mobile.alt || "Kokkinou - Kourkoulas"}
            className="about-image d-sm-none"
          />
          <GatsbyImage
            image={about.image.gatsbyImageData}
            alt={about.image.alt || "Kokkinou - Kourkoulas"}
            className="about-image d-none d-sm-block"
          />
        </div>
        <div
          className="tab-wraper ms-0 pe-2 pe-xl-5"
          style={{ paddingLeft: customMargin }}
        >
          <Tab.Container defaultActiveKey="about">
            <Row className="my-5">
              <Col xl={4} className="tab-nav mb-5 ">
                <Nav variant="pills" className="flex-column">
                  <Row>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="about">
                          <h3>
                            <span className="link-underline-hover">
                              Kokkinou Kourkoulas
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="awards">
                          <h3>
                            <span className="link-underline-hover">
                              Awards & Distinctions
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="collaborators">
                          <h3>
                            <span className="link-underline-hover">
                              Collaborators
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="lectures">
                          <h3>
                            <span className="link-underline-hover">
                              Lectures - Interviews
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col md={6} xl={12}>
                      <Nav.Item className="mb-4">
                        <Nav.Link eventKey="projects">
                          <h3>
                            <span className="link-underline-hover">
                              List of Projects
                            </span>
                          </h3>
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Row>
                </Nav>
              </Col>
              <Col xl={8} className="tab-content">
                <Tab.Content className="ps-lg-3">
                  <Tab.Pane eventKey="about">
                    <Row>
                      <Col md={6} className="mb-4">
                        <PrismicRichText
                          field={about.about_kokkinou_english.richText}
                        />
                      </Col>
                      <Col md={6}>
                        <PrismicRichText
                          field={about.about_kourkoulas_english.richText}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col sm={6} className="mb-4">
                        <PrismicRichText field={about.about_greek.richText} />
                      </Col>
                      <Col sm={6}>
                        <PrismicRichText
                          field={about.about_kourkoulas_greek.richText}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="awards" className="about-awards">
                    <Row>
                      <Col sm={6} className="mb-4">
                        <PrismicRichText
                          field={about.awards_english.richText}
                        />
                      </Col>
                      <Col sm={6}>
                        <PrismicRichText field={about.awards_greek.richText} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="collaborators"
                    className="about-collaborators"
                  >
                    <Row>
                      <Col sm={6} className="mb-4">
                        <PrismicRichText
                          field={about.collaborators_english.richText}
                        />
                      </Col>
                      <Col sm={6}>
                        <PrismicRichText
                          field={about.collaborators_greek.richText}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="lectures" className="about-lectures">
                    <Row>
                      <SliceZone
                        slices={lectures.document.data.body}
                        components={components}
                      />
                      {/* <Col md={6} className="mb-4">
                        <PrismicRichText
                          field={about.lectures_english.richText}
                        />
                      </Col>
                      <Col md={6}>
                        <PrismicRichText
                          field={about.lectures_greek.richText}
                        />
                      </Col> */}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="projects" className="about-projects">
                    <Row>
                      <Col md={6} className="mb-4">
                        <PrismicRichText
                          field={about.list_of_projects_english.richText}
                        />
                      </Col>
                      <Col md={6}>
                        <PrismicRichText
                          field={about.list_of_projects_greek.richText}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query aboutQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAbout(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        subtitle
        image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        image_mobile {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        photographer_name
        about_kokkinou_english {
          richText
        }
        about_greek {
          richText
        }
        about_kourkoulas_english {
          richText
        }
        about_kourkoulas_greek {
          richText
        }
        awards_english {
          richText
        }
        awards_greek {
          richText
        }
        collaborators_english {
          richText
        }
        collaborators_greek {
          richText
        }
        lectures_english {
          richText
        }
        lectures_greek {
          richText
        }
        list_of_projects_english {
          richText
        }
        list_of_projects_greek {
          richText
        }
        lectures {
          document {
            ... on PrismicLectures {
              id
              data {
                title {
                  text
                }
                image {
                  gatsbyImageData(placeholder: BLURRED)
                  alt
                }
                image_mobile {
                  gatsbyImageData(placeholder: BLURRED)
                  alt
                }
                photographer_name
                body {
                  ... on PrismicLecturesDataBodyEnglishLectures {
                    id
                    slice_type
                    items {
                      lecture_title {
                        text
                      }
                      lecture_description {
                        richText
                      }
                      lecture_video {
                        embed_url
                        html
                        title
                      }
                    }
                  }
                  ... on PrismicLecturesDataBodyGreekLectures {
                    id
                    slice_type
                    items {
                      lecture_title {
                        text
                      }
                      lecture_description {
                        richText
                      }
                      lecture_video {
                        embed_url
                        html
                        title
                      }
                    }
                  }
                }
                title {
                  text
                }
              }
            }
          }
      }
      }
    }
  }
`;

export default AboutPage;
